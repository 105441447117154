<template>
  <van-nav-bar
    :title="title"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in items" :key="item">
      <div style="margin:7px 0 0 0;padding:15px;background:#fff;line-height:1.6;">
        <p>
          <small>买家称呼：{{ item.name }}</small>
        </p>
        <p v-if="item.phone !== ''">
          <small>手机号：{{ item.phone }}</small>
        </p>
        <p>
          <small>关系过期：{{ item.expireTime }}</small>
        </p>
        <van-button type="primary" size="small" @click="onDelete(item.customerId)">删除</van-button>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const Cookies = inject('Cookies')
    const state = reactive({
      searchKey: '',
      page: 1,
      finished: false,
      loading: false,
      items: [],
      userId: Cookies.get('userId'),
      storeId: Cookies.get('storeId'),
      staffId: Cookies.get('staffId'),
      title: ''
    })
    const onDelete = (id) => {
      Dialog.confirm({
        title: '提示',
        message: '操作确认'
      }).then(() => {
        post('/customer.delete', {
          customerIds: id.toString().split(',')
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            reset()
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
      })
    }
    const onClick = (item) => {
      state.inviterId = item.inviteeId
      reset()
    }
    const onClear = () => {
      reset()
    }
    const onSearch = (value) => {
      reset()
    }
    const getData = () => {
      post('/customer.list', {
        pageNum: state.page,
        staffId: state.staffId
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共（' + res.data.totalElements + '人）'
      })
    }
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const init = () => {
      // alert('13456789012'.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3'))
    }
    watch(() => useRoute.query, (to, previous) => {
    })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      onClick,
      onDelete
    }
  }
}
</script>

<style scoped>
</style>
